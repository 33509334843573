/* About page component */

import {
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { FaEnvelope, FaFacebook, FaInstagram, FaPhone } from "react-icons/fa"
import Seo from "../seo"

const AboutPage = () => {
  return (
    <>
      <Seo title="About" />
      <Flex
        direction="column"
        justify="center"
        align="center"
        py={[4, 8]}
        bg="gray.700"
      >
        <Heading as="h1" size="xl" color="teal.300" mb={2} textAlign="center">
          About
        </Heading>
        <Text color="gray.50" textAlign="center">
          Who we are
        </Text>
      </Flex>
      <Container maxW="container.xl" py={[4, 8]}>
        <Stack
          direction={["column", "column", "row"]}
          spacing={[4, 4, 8]}
          maxW="1000px"
          minH="50vh"
        >
          <Center w="100%">
            <Image
              src=""
              fallbackSrc="https://via.placeholder.com/300"
              boxSize={["200px", "300px"]}
              alt="Dawa Sherpa"
            />
          </Center>
          <Flex direction="column" justify="center">
            <Text py={2}>
              Hello! My name is{" "}
              <Text as="span" fontWeight="semibold">
                Dawa Sherpa
              </Text>
              , founder of Sherpa Home Improvement. I started this company, in
              2010, as a means of providing high quality home improvement &
              repair services at a fair price. I love helping others make their
              dream home a reality. Sherpa Home Improvement is a family-owned
              business based in{" "}
              <Text as="span" fontWeight="semibold">
                Vienna, VA
              </Text>
              .
            </Text>
            <Text py={2}>
              In my spare time, I enjoy gardening and barbequing with my family
              and friends.
            </Text>
            <Text py={2}>
              If you have any questions or to request a free estimate for your
              next project, please feel free to contact me.
            </Text>
            <HStack spacing={4} justify="center" py={2}>
              <IconButton
                as={GatsbyLink}
                icon={<FaEnvelope />}
                colorScheme="yellow"
                to="/contact"
                aria-label="Send email"
              />
              <IconButton
                as={Link}
                icon={<FaPhone />}
                colorScheme="yellow"
                href="tel:+1-703-895-5538"
                aria-label="Call us"
                isExternal
              />
              <IconButton
                as={Link}
                icon={<FaFacebook />}
                colorScheme="yellow"
                href="https://www.facebook.com/sherpahomeimprovement"
                aria-label="Facebook profile"
                isExternal
              />
              <IconButton
                as={Link}
                icon={<FaInstagram />}
                colorScheme="yellow"
                href="https://www.instagram.com/sherpahomeimprovement"
                aria-label="Instagram profile"
                isExternal
              />
            </HStack>
          </Flex>
        </Stack>
      </Container>
    </>
  )
}

export default AboutPage
